/**
 * @author visix
 */

(function($) {

    $.fn.print = function(printerType, qrCodeContainer) {

        var PRINT_PREVIEW = $("body");

        drawQrCode = function() {
            var $qrCodeContainer = $(this);
            $(this).append(
                new QRCode({
                    msg   :   $qrCodeContainer.parent().prev().val(),
                    dim   :   256,
                    pad   :   0
                })
            );
        };

        function savePng(pngBlob, qrValue, printRow) {
            var formData = new FormData()
            var qrCodeFileName = qrValue.val().replace(/\//g, '_') + ".png";
            var printData = collectPrintData(printRow);
            formData.append('pngFile', new File([pngBlob], qrCodeFileName));
            var ajaxData = $.extend({},iAjaxData,{
                url : qrValue.data("url"),
                data: formData,
                processData: false,
                contentType: false,
                success: function() {
                    if(printerType === 'LABEL') {
                        window.DoPrint(qrCodeFileName, printData);
                        printoutSuccess(printRow);
                    }
                }
            });
            $.ajax(ajaxData);
        }

        function createPng(path, svgSize, qrValue, printRow) {
            var canvas = document.createElement("canvas");
            var size = 1024;
            canvas.width = size;
            canvas.height = size;
            var ctx = canvas.getContext("2d");
            var p = new Path2D(path);
            ctx.transform(size/svgSize, 0, 0, size/svgSize, 0, 0);
            ctx.fill(p);
            canvas.toBlob(function(blob) {
              savePng(blob, qrValue, printRow)
            });
        }

        function collectPrintData(printRow) {
            var printTitle = printRow.find("[name='print-title']").val();
            var printLabel1 = printRow.find("[name='print-label1']").val();
            var printLabel2 = printRow.find("[name='print-label2']").val();
            return {
                "PRINT_TITLE": printTitle,
                "PRINT_LABEL1": printLabel1,
                "PRINT_LABEL2": printLabel2
            };
        }

        function printData() {
            iShowMask(PRINT_PREVIEW);
            var printRow = $(this).closest(qrCodeContainer);
            var divToPrint = printRow.find(".qrcode-container");
            var qrValue = divToPrint.prev();
            var path = divToPrint[0].firstChild.firstChild.getAttribute("d")
            var size = divToPrint[0].firstChild.viewBox.baseVal.height;
            createPng(path, size, qrValue, printRow);

            if(printerType === 'DEFAULT') {
                newWin= window.open("");
                newWin.document.write($(divToPrint).closest(".print-area").html());
                newWin.print();
                newWin.close();
                printoutSuccess(printRow);
            }
        }

        function printoutSuccess(printRow) {
            iHideMask(PRINT_PREVIEW);
            printRow.find(".order-detail-printout-success").show();
        }

        return this.each(function() {
            $('.fpPrint').on('click', printData);
            $(".qrcode-container").each(drawQrCode);
        });
    }

}(jQuery));